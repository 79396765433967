.ui-table tbody td {
  .ui-button {
    background: #fff !important;
    color: $primaryColor !important;
    border: $primaryColor;
  }
}
.ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background: #e7ebef !important;
    color: $secondaryColor !important;
    .ui-sortable-column-icon {
      color: $secondaryColor !important;
    }
}

.ui-table .ui-sortable-column.ui-state-highlight {
  color: $primaryColor !important;
}
.ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: $primaryColor !important;
}
