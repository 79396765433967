// @import './assets/sass/ccass_fonts.scss';

.wrapper-card-template{
background: #FFFFFF;
box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
border-radius: 4px;
font-family: Gilroy-R;
}

.primary-sub-heading-tag{
font-size: 16px;
color: #040D1C;
opacity: 3;
mix-blend-mode: normal;
display: flex;
align-items: center;
}