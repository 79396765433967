.g-btn {
	.p-button {
		background: $primaryColor;
	}
	.p-button:hover {
		background: $primaryColor;
	}
	.p-button:focus {
		box-shadow: none;
	}
}
.g-dropdown {
	.p-dropdown {
		width: 12rem;
		height: unset;
		margin: 10px;
		border-color: $primaryColor;
		box-shadow: none;
	}
	.p-dropdown:hover {
		border-color: $primaryColor;
	}
	.p-dropdown-trigger-icon,
	.p-dropdown-label,
	.p-placeholder {
		color: $primaryColor;
	}
	.p-dropdown:focus-within {
		box-shadow: none;
		border-color: $primaryColor;
	}
	.p-dropdown .p-dropdown-label.p-placeholder {
		color: $primaryColor;
	}
}
.g-calendar {
	.p-calendar-w-btn > input {
		width: 7rem;
	}
	.p-datepicker-trigger,
	.p-datepicker-trigger:hover {
		background: white;
		// color: white;
		border: none;
	}
	.p-datepicker-trigger:focus {
		box-shadow: none;
		outline: none;
		outline-offset: 0;
	}
	.p-inputtext::placeholder {
		color: $primaryColor;
	}
	.p-inputtext:focus {
		box-shadow: none;
		outline: none;
		outline-offset: 0;
	}
	.p-inputtext {
		border: none;
		color: $primaryColor;
		font-size: large;
	}
	.p-calendar-w-btn .p-datepicker-trigger {
		color: #757575;
	}
	.p-calendar {
		width: 170px;
	}
}
.p-datepicker table td {
	padding: 0 !important;
}

.p-datepicker-buttonbar {
	padding: 8px !important;
}
.p-datepicker-buttonbar > .p-button {
	padding: 5px 10px;
	margin-top: 0.5rem !important;
	color: $primaryColor;
	border: 1px solid $primaryLightenColor !important;
	font-weight: bold !important;
	font-size: 14px;
}
.p-datepicker-buttonbar > .p-button:hover {
	color: $primaryColor !important;
	border: 1px solid $primaryColor !important;
}
// .p-datepicker-trigger {
//   width: 20px !important;
// }

// .p-datatable-thead>tr>th {
//     overflow: hidden !important;
//     white-space: nowrap !important;
// }

// .p-card-shadow{
// 	box-shadow: none !important;
// }
// .p-card {
// 	box-shadow: none !important;
// }
.align-items-center {
	align-items: center;
}
.align-items-baseline {
	align-items: baseline;
}
.justify-content-flexend {
	justify-content: flex-end;
}
.justify-content-spacebetween {
	justify-content: space-between;
}
.justify-content-center {
	justify-content: center;
}
.p-blockui.p-component-overlay {
	z-index: 100;
	background: rgba(0, 0, 0, 0.3);
}
.p-datepicker table td > span.p-highlight {
	color: white;
}
.disp-flex {
	display: flex;
}
.b-b-1 {
	border-bottom: 1px solid #e0e0e0;
}
// .p-dialog-mask.p-component-overlay{
//   pointer-events: none !important;
// }
