.p-inputtext {
  padding: 10px;
  font-weight: bold;
  color: #707070;
  width: 100%;
}
.p-dropdown{
  width: 100%;
}
.p-dropdown:not(.p-disabled).p-focus{
  box-shadow: none;
}
.p-radiobutton .p-radiobutton-box.p-highlight{
  border-color: $primaryColor;
  background: $primaryColor;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
  width: 10px;
  height: 10px;
}
.p-checkbox .p-checkbox-box.p-highlight{
  background: $primaryBtnBGColor;
  border-color: $primaryBtnBGColor;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
  border-color: $primaryColor !important;
  background: $primaryColor;
}
.p-dropdown-panel .p-dropdown-item.p-highlight {
	background: $primaryColor !important;
}
.p-datepicker table td > span.p-highlight{
  background: $primaryColor;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
  color: #ffffff;
}