.ui-paginator .ui-paginator-pages {
  padding: 0em 0.5em 0em 0.5em !important;
}
.ui-paginator .ui-paginator-page.ui-state-active {
  background: $primaryColor;
  color: $primaryTextColor;
}
*:focus {
  outline: none;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	background: #114591 !important;
	color: #ffffff !important;
	box-shadow: none !important;
	border-color: unset !important;
	border-radius: 5px !important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
	height: 1.5rem !important;
	min-width: 1.5rem !important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
	border-radius: 5px !important;
}
.p-paginator {
	padding: 0 !important;
}
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
	border: 1px solid #d9d9d9;
	background: white;
	font-weight: bold !important;
	height: 1.5rem !important;
	min-width: 1.5rem !important;
	color: #495057 !important;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
	border: 1px solid #d9d9d9;
	background: white;
	font-weight: bold !important;
	height: 1.5rem !important;
	min-width: 1.5rem !important;
	color: #495057 !important;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight) {
	border: 1px solid #d9d9d9;
	background: white;
	font-weight: bold;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
	border: 1px solid #d9d9d9;
	background: white;
	font-weight: bold;
}
.p-link:focus {
	box-shadow: none !important;
}
.p-paginator-icon {
	font-weight: bold;
}