.p-button .p-button-text {
    padding-top: 7px;
    padding-bottom: 6px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
  }

    .spclheight {
      text-align: center;
      font-weight: normal !important;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 3px;
      height: 19px !important;
      font-size: 11px !important;
    }
		
  .p-button-sm {
    height: 32px;
  }

  .p-button:disabled{
    pointer-events: none !important;
  }
  .p-button {
    background: $primaryBtnBGColor;
    border: 1px solid $primaryBtnBorderColor;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 4px;
  }
  .p-button:focus{
    box-shadow: none;
  }

  // .p-datepicker-trigger{
  //   color: #5F6772;
  // }

  .p-button:active {
    transform: translateY(1px);
  }

  .p-button:enabled:hover {
    background: $primaryBtnHoverColor;
    border: 1px solid $primaryBtnBorderColor;
  }

  .p-button.p-button-secondary:enabled {
    color: $secondaryBtnTextColor !important;
    background: $secondaryBtnBGColor !important;
    border: 1px solid $secondaryBtnBorderColor !important;
  }

  .p-button.p-button-secondary:disabled {
    color:$secondaryBtnTextColor !important;
    background: $secondaryBtnBGColor !important;
  }

  .p-button.p-button-secondary:enabled:hover {
    border: 1px solid  $secondaryBtnBorderHoverColor !important;
    color: $secondaryBtnTextColor !important;
  }

  .p-button.p-button-secondary:active {
    transform: translateY(1px);
  }
  .p-button .p-button-icon-right{
    margin-bottom: 0.15rem;
  }



  //Upload
  .upload-btn {
    .p-button-text-icon-left .p-button-text {
      padding-left: 25px !important;
    }
  }

  // search and clear button in add-role
  .search-clear-btn {
    .p-button-text-icon-left .p-button-text {
      padding-left: 40px !important;
    }
  }

  //Dashboard Filter Buttons
  .filter-btn {
    .p-button .p-button-text {
      padding-top: 3px !important;
      padding-bottom: 2px !important;
      padding-left: 5px !important;
      padding-right: 26px !important;
      background: #fff !important;
      color: #939393 !important;
      font-weight: normal !important;
      font-size: 11px !important;
    }
    .p-button {
      border: 1px solid #D3D3D3 !important;
    }
    .ui-corner-all {
      border-radius: 3px !important;
    }
    .p-button-text-icon-right .fa {
      color: #D3D3D3 !important;
      font-size: 11px !important;
    }
    .p-button:enabled:active {
      border: 1px solid #D3D3D3 !important;
      background: #fff !important;
      color: #D3D3D3 !important;
    }
  }
  .file-upload-btn-ref .p-button-text{
    padding-right:30px !important
}
.file-upload-btn-ref .p-button-icon-right{
  top: 58% !important;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus{
  box-shadow: none;
}
.p-button.p-button-success, .p-button.p-button-warning{
  background: $primaryBtnBGColor;
  border: 1px solid $primaryBtnBGColor;
  color: #ffffff;
}
.p-button.p-button-success:enabled:hover, .p-button.p-button-warning:enabled:hover {
	background: $primaryBtnHoverColor;
	border: 1px solid $primaryBtnHoverColor;
	color: #ffffff;
}
.p-button.p-button-success:enabled:focus, .p-button.p-button-warning:enabled:focus {
	box-shadow: none;
}