.m-n-l-10 {
  margin-left: -10px;
}

.m-0 {
  margin: 0px !important;
}
.m-5{
  margin: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-19 {
  margin-left: 19px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.mn-l-15 {
  margin-left: -15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.m-ln-14 {
  margin-left: -14px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-t-3 {
  margin-top: 3px !important;
}

.m-t-19 {
  margin-top: 19px !important;
}
.m-l-70 {
  margin-left: 70px !important;
}
.m-l-18 {
  margin-left: 18px;
}
.m-r-18 {
  margin-right: 18px;
}

.m-ln-8 {
  margin-left: -8px;
}
