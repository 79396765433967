.ui-datepicker select.ui-datepicker-month {
  background-color: #fff !important;
  height: 32px;
  font-family: "Font_Regular" !important;
}

.ui-datepicker select.ui-datepicker-year {
  background-color: #fff !important;
  height: 32px;
  font-family: "Font_Regular" !important;
}
.ui-datepicker .ui-datepicker-header a {
  margin-top: 5px;
}

//Changing saturday and sunday dates colors
.ui-datepicker table tr td:first-child + td + td + td + td + td + td {
  a {
    color: red !important;
  }
}
.ui-datepicker table tr td:first-child {
  a {
    color: red !important;
  }
}
.ui-datepicker.ui-widget {
  z-index: 99999 !important;
}
.ui-datepicker td {
  cursor: pointer !important;
}
.ui-calendar.ui-calendar-w-btn input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ui-datepicker-monthpicker.flex-calendar-ref
  > .ui-datepicker-group
  > .ui-datepicker-header
  > .no-header
  + .ui-datepicker-prev {
  top: 15px !important;
}
.ui-datepicker-monthpicker.flex-calendar-ref
  > .ui-datepicker-group
  > .ui-datepicker-header
  > .no-header
  + .ui-datepicker-prev 
  + .ui-datepicker-next {
  top: 15px !important;
}
.ui-datepicker-monthpicker.flex-calendar-ref > .ui-datepicker-group > .ui-datepicker-header {
  .calender-header {
    padding: 4px 10px 10px 10px;
    border-bottom: 1px solid #dedede;
  }
  .ui-datepicker-prev {
    top: 47px;
  }
  .ui-datepicker-next {
    top: 47px;
  }
  .ui-datepicker-title {
    padding-top: 10px;
  }
}

.ui-datepicker-monthpicker.flex-calendar-ref .ui-monthpicker {
  width: 300px;
  a {
    border-top: 1px solid #dedede !important;
    padding: 15px !important;
    color: #000000 !important;
  }
  a.ui-state-active {
    background-color: white !important;
    color: black !important;
  }
}
