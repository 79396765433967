.ui-dropdown {
  width: 100% !important;
}

body .ui-widget-header {
  background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
}

.validation-red {
  color: #e17576;
}
.validation-success {
  color: green;
}

.text-center {
  text-align: center !important;
}

.ui-growl-image {
  display: none;
}

.ui-growl-message {
  margin-left: 0px !important;
}

.ui-growl {
  z-index: 99999999999 !important;
  p {
    color: #fff !important;
  }
}

.ui-fileupload-choose input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  direction: ltr;
  cursor: pointer;
}

.ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  margin-left: -2em !important;
}

.profilePic {
  max-height: 250px !important;
  max-width: 300px !important;
  border-radius: 50% !important;
  border: 6px solid white;
}


.logo-svg {
  svg {
    width: 130px;
    fill: white !important;
  }
}

.pd-0 {
  padding: 0px !important;
}

.float-right {
  float: right;
}

.svg {
  height: 50%;
  width: 50%;
}

// footer styles
.footer {
  position: fixed;
  left: 0px;
  font-size: 11px;
  bottom: 0px;
  width: 100%;
  color: white;
  height: 30px;
  z-index: 10000;
}

.copy-right {
  float: left;
  margin: 9px 0px 0px 30px;
  color: #fff;
}

.feedback {
  float: right;
  margin: 9px 30px 0px 0px;
  color: #fff;
}

.reset {
  width: 100% !important;
}

.csopId-color {
  font-size: 20px;
  margin-top: 18px;
}

.approved-clr {
  color: #ffffff !important;
}

.layout-wrapper {
  .topbar {
    #topbar-notif {
      display: none;
      position: absolute;
      right: 70px;
      top: 80px;
      width: 41%;
      list-style-type: none;
      padding: 0;
      margin: 0;
      -webkit-animation-duration: .5s;
      -moz-animation-duration: .5s;
      animation-duration: .5s;
      &.topbar-menu-visible {
        display: block;
      }
    }
  }
}

.view-all {
  float: right;
}

.view-all-mr {
  margin-top: 26px;
  margin-right: 20px;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
}

.user-img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.user-img-svg {
  svg {
    overflow: hidden;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
}

.notif-content-alg {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
}

.float-left {
  float: left;
}

.w-50 {
  width: 50px !important;
}

.w-7 {
  width: 7% !important;
}
.w-5 {
  width: 5% !important;
}
.w-5-pr {
  width: 5% !important;
}

.width-75 {
  width: 75px !important;
}

.w-56 {
  height: 56px !important;
  width: 56px !important;
}

// Profile bar
.welcome {
  font-size: 24px;
  text-decoration: none !important;
  font-weight: bold
}

.m-l-r {
  margin-left: 153px;
  margin-right: 153px;
}

.user-css {
  font-size: 24px;
  text-decoration: none !important;
  font-weight: bold;
  color: $primaryColor;
}
.fund-name {
  font-size: 20px;
  text-decoration: none !important;
  font-weight: bold;
}

.caption {
  margin: 0px !important;
  opacity: 0.5 !important;
  font-size: 13px;
  font-weight: bold;
}

.date-time {
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
}

.last-login {
  color: #0b3063;
  font-size: 13px;
  margin: 0px;
  font-family: 'Font_Light';
}

.text-align-right {
  text-align: right;
}

.text-center {
  text-align: center !important;
}

.top-btm-mrg {
  margin-bottom: 65px;
}

// Inputs validation border
.ui-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid>.ui-dropdown,
p-autocomplete.ng-dirty.ng-invalid>.ui-autocomplete>.ui-inputtext,
p-calendar.ng-dirty.ng-invalid>.ui-calendar>.ui-inputtext,
p-chips.ng-dirty.ng-invalid>.ui-inputtext,
p-inputmask.ng-dirty.ng-invalid>.ui-inputtext,
p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
p-listbox.ng-dirty.ng-invalid .ui-inputtext,
p-multiselect.ng-dirty.ng-invalid>.ui-multiselect,
p-spinner.ng-dirty.ng-invalid>.ui-inputtext,
p-selectbutton.ng-dirty.ng-invalid .ui-button,
p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border-color: #f44336 !important;
}

// Process status styles
.Draft {
  border: 1px solid #d5d8db;
  background-color: #ffffff;
  color: #8fb0fc;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
}

.myReview {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  color: #ff940a;
  text-align: center;
  background-color: #ffedcd;
}

.terminated {
  color: #ec7a85;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #f8dee5;
}

.approved {
  color: #01d87a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #caf9ef;
}

.backOfcReview {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

.teamHeadReview {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

.lcReview {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

.hwmReview {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

.rmReview {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

.armReview {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

.finalReview {
  color: #d08b63;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #fbe9de;
}

.rmReopen {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

.rmReOpenedReview {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

.thReOpenedReview {
  color: #ff940a;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffedcd;
}

/* for custom checkbox */

/* The container */

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 42px;
  width: 42px;
  background-color: #eee;
  border-radius: 10px;
}

/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
  background-color: #ccc;
}

/*#2196F3*/

/* When the checkbox is checked, add a blue background */

.container input:checked~.checkmark {
  background-color: none;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container .checkmark:after {
  left: 20px;
  top: -9px;
  width: 11px;
  height: 38px;
  // border: solid #114591;
  border-width: 0px 5px 5px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
}

.svg-alg {
  float: left !important;
}

.case-alg {
  float: left !important;
}

/* Absolute Center Spinner */

.loading {
  position: absolute;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.spinner {
  position: absolute;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */

.loading:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.253);
}

/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.word-wrap {
  word-wrap: break-word;
}

.danzer-font {
  color: red;
}

.v-a-t {
  vertical-align: top !important;
}

// Button styles
.review-btn {
  width: 280px !important;
  height: 60px !important;
  background-color: #FE5751 !important;
  border: 1px solid #FE5751 !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  margin-left: 2.0%;
  font-weight: bolder;
  font-size: 17px;
}

.ui-widget-overlay {
  background-color: #ffffff !important;
  opacity: 0.5;
  z-index: 100 !important;
}

.mask {
  opacity: 0.5 !important;
  pointer-events: none !important;
  // background-color: #748ab1;
}

.bordernone {
  border: none !important;
}

.disableinputs {
  pointer-events: none !important;
  opacity: 0.6;
}

.tab-title-alg {
  font-size: 24px;
  color: #323C47;
  font-weight: bold;
}

.small-label {
  font-size: 13px;
  color: #0B3063;
  opacity: 0.6;
}

.button-text {
  float: right;
  font-size: 18px;
  font-weight: normal;
}

.catageory-font {
  font-size: 24px;
  color: #323C47;
}

.label-font {
  font-size: 15px;
  color: #A2A2A2;
}


.inline-text {
  white-space: normal;
}

.break-word {
  word-wrap: break-word;
}
.inline-flex {
  display:inline-flex !important;
}

/* Font Sizes */
.f-s-10{
  font-size: 10px !important;
}
.f-s-12{
  font-size: 12px !important;
}
.f-s-14{
  font-size: 14px !important;
}
.f-s-15{
  font-size: 15px !important;
}
.f-s-16{
  font-size: 16px !important;
}
.f-s-18{
  font-size: 18px !important;
}
.f-s-20{
  font-size: 20px !important;
}
.csop-navy-color{
  color:#7b7b7a !important;
  font-size: 14px;
  font-weight: bold;
}

.pos-relative{
  position: relative;
}

.mask-kyc {
  opacity: 0.5 !important;
  pointer-events: none !important;
  background-color: #ffffff;
}
.view-errror-text{
  display: inline-block;
  padding-top: 2px;
  color:#114591;
  font-weight: bold;
}
