.card-bodydiv{
    padding: 2px 16px;
    background: url("/assets/images/UserGroup.png") no-repeat;
    background-repeat:no-repeat;
    text-align:right;
    background-size:80px;
    background-position-y: bottom;
    background-color:#3E8BDF;
    opacity:0.9;
    color:#fff;
    height:130px;
    border:0px;
}
.card-active{
    background-image:url("/assets/images/ActiveUser.png") !important;
    background-color:#00C7D3 !important;
}
.card-inactive{
    background-image:url("/assets/images/InactiveUser.png") !important;
    background-color:#F32E55 !important;
}
.count-font {
    font-size: 42px;
    text-align: right;
}

.title-alg{
    border: 1px solid #ccc;
    border-radius: 2px;
    background: #cccccc40;
}
.user-stat-table {
    .ui-table .ui-table-thead>tr>th, .ui-table .ui-table-tbody>tr>td {
      height: 41px !important;
    }
    .ui-table tbody tr td div, .ui-table tbody tr td p {
      font-weight: normal !important;
    }
  }
