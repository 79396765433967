.page-label {
  font-size: 24px;
  font-weight: bold;
  color: $primaryColor !important;
}
.stress-label {
  font-size: 18px;
  font-weight: bold;
  // color: #0c3063;
}
