.priv-tags{
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #edeff2;
    border-color: $primaryColor;
    color: $primaryColor;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link, .p-tabview .p-tabview-nav{
  background: #edeff2;
}
.priv-tags .p-tabview .p-tabview-nav li:hover{
  background: #edeff2;
  color:$primaryColor;
  border-bottom: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus{
  box-shadow: none;
}
.p-tabview .p-tabview-panels{
  padding: 0;
}
.p-tabview{
  border-top: 1px solid #e7ebef !important;
  border-left: 1px solid #e7ebef !important;
  border-right: 1px solid #e7ebef !important;


}

}