.p-dialog-header{
    display: block !important;
    padding-bottom: 15px !important;
    border-bottom: 1px solid #ccc !important;
    .close-icon {
        text-align: right;
    }
}
.p-dialog .p-dialog-footer{
    padding-top: 24px;
    border-top: 1px solid #ccc;
    padding-left: 28px;
    text-align: left;
}
.p-dialog .p-dialog-content{
    padding:20px;
}
.confirm-popup{
    .p-dialog-header{
        padding: 0px;
        display: block !important;
        border: 0px solid #ccc !important;
        .close-icon {
            text-align: right;
        }
    }
}