.widget-header {
  font-size: 14px;
  font-weight: bold;
  background: #DAE5F1;
  // color: #114591;
  .hdr-sp{
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
  }
  .sp-width{
    width: 30px;
  }
  .widget-remove-icon-right {
    svg {
      width:16px;
      padding-left: 5px;
      float: right;
      cursor: pointer;
    }
  }
}

.widget-remove-icon-right {
    svg {
      width:16px;
      padding-left: 5px;
      float: right;
      cursor: pointer;
    }
}

.widget-content {
  // .edit-save-icon{
  //   // color: #0f4591 !important;
  // }
  .widget-remove-icon-right {
    svg {
      width:16px;
      padding-left: 5px;
      float: right;
      cursor: pointer;
    }
  }
  .editor-height {
    height: 320px;
    overflow-y: auto;
  }
}
.widget-content p {
  margin: 0px;
  color: #757575;
}

.widget-table {
  table {
    table-layout:fixed;
    width: 100%;
    border-collapse: collapse;
    th {
      background: #F2F2F2;
      padding: 10px 5px 10px 5px;
      height: 22px;
      // color: #114591;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
    td {
      // color: #114591;
      font-size: 12px;
      padding: 5px;
      height: 30px;
    }
    tr:nth-child(odd) {
      background-color: #DDE6F2;
    }
    .col1-bg {
      background: #F2F2F2 !important;
    }
    .fund-info-td-lbl {
      // color: #114591;
      font-size: 12px;
      font-weight: bold;
    }
    tr.fund-info-td-val {
      color: #000000;
      font-size: 14px;
    }
  }

}

.header-border {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.header-height {
  position: relative;
  height: 200px;
  .fund-name {
    font-size: 15px;
    font-weight: bold;
    // color:#006DF0;
  }
  .stock-code {
    font-size: 12px;
    color: #000000;
  }
  .as-of-date{
    font-size: 10px;
    color: #000000;
  }
}

.tmplt-approval-pending {
  color : #FF7B1A !important;
  font-size: 18px;
}
.chat-svg {
  svg {
    height: 15px !important;
    width: 15px !important;
  }
}
