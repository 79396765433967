.ui-accordion .ui-accordion-header .rm-icon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  padding-top: 5px;
}

.ui-accordion .ui-accordion-header {
  background: $primaryColor !important;
  border-radius: 5px !important;
  opacity: 90% !important;
  height: 52px !important;
  font-size: 20px !important;
  vertical-align: middle;
  font-weight: bold;
}

.ui-accordion .ui-accordion-header a {
  color: white !important;
  text-decoration: none !important;
}