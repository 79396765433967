$primaryColor: var(--primaryColor);
$secondaryColor: var(--secondaryColor);
$primaryTextColor: var(--primaryTextColor);
$secondaryTextColor: var(--secondaryTextColor);
$loginBg: var(--loginBg) no-repeat center center fixed;
$leftMenu: var(--leftMenu);
$primaryDarkenColor:var(--primaryDarkenColor);
$primaryLightenColor:var(--primaryLightenColor);

// Firm-Level Liquidation
$barOne: var(--barOne);
$barTwo: var(--barTwo);
$barThree: var(--barThree);
$barFour: var(--barFour);
$barFive: var(--barFive);
$header: var(--header);
$dashboardCardColor: var( --dashboardCardColor);
$dCardValColor: var( --dCardValColor);
$dCardHeader: var( --dCardHeader);
$menuScroll: var( --menuScroll);

