.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}
.p-8 {
  padding: 8px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}
.p-t-11 {
  padding-top: 10px !important;
}
.p-t-12 {
  padding-top: 10px !important;
}
.p-t-13 {
  padding-top: 10px !important;
}
.p-t-14 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}
.p-t-4 {
  padding-top: 4px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}
.p-l-2 {
  padding-left: 2px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}

.p-l-12 {
  padding-left: 12px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-l-14 {
  padding-left: 14px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}
.p-l-70 {
  padding-left: 70px !important;
}
.p-r-60 {
  padding-right: 60px !important;
}
.p-l-52 {
  padding-left: 52px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-l-16 {
  padding-left: 16px;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-b-2 {
  padding-bottom: 2px !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-l-18 {
  padding-left: 18px !important;
}

.p-t-3 {
  padding-top: 3px !important;
}

.p-r-18 {
  padding-right: 18px !important;
}

.p-b-18 {
  padding-bottom: 18px !important;
}
.p-t-7 {
  padding-top: 7px !important;
}
.p-r-65 {
  padding-right: 65px !important;
}
.p-r-70 {

  padding-right: 70px !important;

}
