.primary-p-table .p-table .p-table-thead > tr > th {
  background: #e7ebef;
  font-family: "Font_Light" !important;
  font-size: 15px !important;
  text-align: left !important;
  border: 0px;
  opacity: 0.7;
  outline: none;
  text-transform: uppercase;
  .p-chkbox .p-chkbox-box {
    width: 20px;
    height: 20px;
    // margin-left: 7px !important;
    line-height: 1.125em;
    border-radius: 2px;
    text-align: center;
  }
  .p-column-title {
    margin-left: 10px !important;
  }
}

.primary-p-table {
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
  .p-table th.p-state-active {
    background: #186ba0 !important;
    font-weight: 700;
    .p-column-title {
      color: white;
    }
  }
  .p-dropdown {
    min-width: unset !important;
    height: unset;
  }
}

.primary-p-table .p-table tbody td {
  border: 0px !important;
  color: #5f6772;
  letter-spacing: 1px;
  padding-left: 20px;
  .viewColor {
    .p-button-text {
      text-align: center;
    }
  }
  .p-button {
    font-size: 15px;
    text-align: center;
    font-weight: normal !important;
  }
  .p-button-text {
    padding-top: 2px !important;
    padding-bottom: 1px !important;
  }
  .p-button:active {
    transform: translateY(1px) !important;
  }
}

.primary-p-table .p-table tbody tr {
  border: none !important;
  height: 52px;
  .case-col-alg .p-cell-data {
    margin-left: 10px !important;
  }
}

.primary-p-table .p-rowgroup-header {
  background: #114591 !important;
}

.primary-p-table .p-rowgroup-header:hover {
  background: #114591 !important;
}

.primary-p-table .p-rowgroup-header td span.p-rowgroup-header-name {
  color: #ffffff !important;
}

.primary-p-table .p-widget-header .p-table tbody tr:hover {
  background-color: #114591 !important;
}

.primary-p-table .p-table table {
  border: none;
  font-family: "Font_Regular" !important;
}

.primary-p-table .p-table tbody {
  border: none;
  border-top-width: 0px !important;
}

.primary-p-table .p-table .p-table-scrollable-view {
  border: 1px solid #e7ebef;
}

.p-paginator-bottom {
  border: 0px !important;
  background: #ffffff !important;
  margin-top: 30px !important;
}

.p-table-scrollable-header-box {
  margin-right: 0px !important;
}

.mail-config-table-ref .p-table tbody tr td {
  div,
  p,
  span {
    word-break: break-all !important;
    text-overflow: clip !important;
    overflow: visible !important;
    white-space: initial !important;
    font-weight: bold;
  }
}

.p-table tbody tr td {
  div,
  p,
  span {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    font-weight: bold;
  }
}

//loading icon for table
.p-table .p-table-loading-icon {
  font-size: 3em;
}

//Dialog popup for primeng7
// .p-dialog .p-dialog-titlebar {
//   background-color: #ffffff;
//   color: #333333;
//   padding: 1em;
//   font-weight: 700;
//   position: relative;
//   border-bottom: 1.4px solid #d9d9d9;
//   margin-left: 8px;
//   margin-right: 8px;
// }
// .p-dialog.p-widget .p-dialog-titlebar {
//   padding: 0.1em 0.2em;
// }
// .p-dialog-footer {
//   margin-left: 8px;
//   margin-right: 8px;
//   text-align: left;
//   border-top: 1.4px solid #d9d9d9;
// }

.new-toggle-style {
  .p-inputswitch {
    height: 0.75em !important;
  }
  .p-inputswitch .p-inputswitch-slider {
    background-color: #cccccc;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    top: 5px;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover
    .p-inputswitch-slider {
    background-color: #b7b7b7;
  }
  .p-inputswitch:not(.p-state-disabled):hover .p-inputswitch-slider {
    background-color: #b7b7b7;
  }
}
.new-toggle-style-pos {
  .p-inputswitch .p-inputswitch-slider:before {
    background-color: red;
  }
}
.new-toggle-style-neg {
  .p-inputswitch .p-inputswitch-slider:before {
    background-color: green;
  }
}
.secondary-p-table {
  th.p-l-15.p-b-0.p-sortable-column {
    background: #3b5982;
    padding-top: 20px;
    color: #ffffff !important;
    padding-bottom: 20px !important;
    opacity: 0.7;
  }
  span {
    color: #366ccc;
  }
}
.email-config {
  tr.p-table-even {
    background-color: #e7ebef !important;
  }
  .p-table .p-table-thead > tr > th {
    background: #3b5982;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  span.p-column-title {
    color: #ffffff;
  }
  span.p-cell-data {
    color: #366ccc;
  }
  td.ng-star-inserted:nth-child(2) {
    font-weight: bold !important;
  }
  .p-table-resizable tbody td {
    font-weight: normal;
  }
  th.p-sortable-column {
    background: #3b5982 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    color: #ffffff !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(8) {
    padding-left: 40px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(7) {
    padding-left: 35px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(6) {
    padding-left: 35px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(5) {
    padding-left: 30px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(4) {
    padding-left: 25px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(3) {
    padding-left: 25px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(2) {
    padding-left: 20px !important;
  }
  th.p-l-10.p-t-20.p-b-0.p-resizable-column {
    border-right: 1px solid #ffffff;
  }
  th.p-l-10.p-t-20.p-b-0.p-resizable-column:nth-child(8) {
    border-right: none;
  }
}

.primary-table .p-table .p-table-thead > tr > th {
  background: #e7ebef;
  font-family: "Font_Light" !important;
  font-size: 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left !important;
  border: 0px;
  opacity: 0.7;
  outline: none;
  .p-chkbox .p-chkbox-box {
    width: 20px;
    height: 20px;
    // margin-left: 7px !important;
    line-height: 1.125em;
    border-radius: 2px;
    text-align: center;
  }
  .p-column-title {
    margin-left: 10px !important;
  }
}

.primary-table {
  .p-table th.p-state-active {
    background: #186ba0 !important;
    font-weight: 700;
    .p-column-title {
      color: white;
    }
  }
}

//Table Body
.primary-table .p-table tbody td {
  border: 0px !important;
  color: #5f6772;
  letter-spacing: 1px;
  padding-left: 15px;
  .viewColor {
    .p-button-text {
      text-align: center;
    }
  }
  .p-button {
    background: #ffffff !important;
    font-size: 15px;
    height: 25px;
    text-align: center;
    color: #114591;
    border: 1px solid #114591 !important;
    font-weight: normal !important;
  }
  // .p-button:hover {
  //   background: #114591 !important;
  //   color: #ffffff;
  // }
  .p-button-text {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .p-button:active {
    transform: translateY(1px) !important;
  }
}

//Table Row
.primary-table .p-table tbody tr {
  border: none !important;
  height: 52px;
}

//Table Row Group
.primary-table .p-rowgroup-header {
  background: #114591 !important;
}

.primary-table .p-rowgroup-header:hover {
  background: #114591 !important;
}

.primary-table .p-rowgroup-header td span.p-rowgroup-header-name {
  color: #ffffff !important;
}

.primary-table .p-widget-header .p-table tbody tr:hover {
  background-color: #114591 !important;
}

.primary-table .p-table table {
  border: 2px solid #e7ebef;
  font-family: "Font_Regular" !important; // width: auto !important;
}

.risk-table .p-table .p-table-thead > tr > th {
  background: #ffffff !important;
  font-size: 15px !important;
  font-weight: bold;
  height: 60px;
  color: #0b3063;
  text-align: left !important;
  border: 0px !important;
  border-bottom: 2px solid #d9d9d93d !important;
  .p-column-title {
    margin-left: 0px !important;
  }
}

.risk-table .p-widget-content {
  border: 0px !important;
}

.risk-table .p-table tbody td {
  border: 0px !important;
  font-size: 14px;
  font-weight: bold;
  color: #5f6772;
  height: 57px;
  border-bottom: 2px solid #e7ebef !important;
  .p-cell-data {
    margin-left: 0% !important;
  }
}

.risk-table .p-table .p-table-scrollable-body {
  height: 500px !important;
}

.inline-editable-table-alg {
  .p-table tbody td {
    border-style: unset !important;
  }
  .p-table .p-table-thead > tr > th {
    border-color: #ebecf0;
    text-align: left;
    height: 40px;
  }
  .p-table tbody > tr.p-widget-content {
    border-left-style: hidden;
    border-right-style: hidden;
    height: 50px !important;
  }
}
.generate-email-table {
  //for entire table
  .p-table tbody > tr.p-widget-content {
    border-left-style: hidden;
    border-right-style: hidden;
    border-top-style: hidden;
    border-bottom-style: hidden;
  }
  //for Table-Headers
  .p-table .p-table-thead > tr > th {
    border-color: #f0f3f8;
    color: #ffffff;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    background-color: #3f5c83;
    border-style: unset !important;
  }
  //For Table-Body
  .p-table tbody td {
    color: #366ccc;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    border-style: unset !important;
  }
  .p-table-odd {
    background: #ffffff;
  }
  .p-table-even {
    background: #f2f5f9;
  }
}
.email-status {
  span {
    .mail-status {
      color: limegreen;
    }
  }
}
//Fund-Dashboard-old
.db-header-alg .p-table .p-table-thead > tr > th {
  background: #e7ebef;
  font-family: "Font_Light" !important;
  font-size: 15px !important;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: left !important;
  border: 0px;
  opacity: 0.7;
  outline: none;
  .p-chkbox .p-chkbox-box {
    width: 20px;
    height: 20px;
    margin-left: 7px !important;
    line-height: 1.125em;
    border-radius: 2px;
    text-align: center;
  }
  .p-column-title {
    margin-left: 10px !important;
  }
}

.db-header-alg {
  .p-state-highlight {
    border-color: #ffffff !important;
    background: #ffffff !important;
    color: #ffffff;
  }
  .p-table th.p-state-active {
    background: #186ba0 !important;
    font-weight: 700;
    .p-column-title {
      color: white;
    }
  }
}

.p-table .p-table-tbody > tr {
  border: 0px !important;
}

.db-header-alg .p-table tbody td {
  border: 0px !important;
  color: #5f6772;
  letter-spacing: 1px;
  padding-left: 15px;
  .viewColor {
    .p-button-text {
      text-align: center;
    }
  }
  .p-button {
    background: #ffffff !important;
    font-size: 15px; // height: 25px;
    text-align: center;
    color: #114591;
    border: 1px solid #114591 !important;
    font-weight: normal !important;
  }

  // .p-button:hover {
  //   background: #114591 !important;
  //   color: #ffffff;
  // }
  .p-button-text {
    padding-top: 2px !important;
    padding-bottom: 1px !important;
  }
  .p-button:active {
    transform: translateY(1px) !important;
  }
}

.db-header-alg .p-table tbody tr {
  border: none !important;
  height: 52px;
  .case-col-alg .p-cell-data {
    margin-left: 10px !important;
  }
}

.db-header-alg .p-rowgroup-header {
  background: #114591 !important;
}

.db-header-alg .p-rowgroup-header:hover {
  background: #114591 !important;
}

.db-header-alg .p-rowgroup-header td span.p-rowgroup-header-name {
  color: #ffffff !important;
}

.db-header-alg .p-widget-header .p-table tbody tr:hover {
  background-color: #114591 !important;
}

.td-case-scss {
  font-size: 16px !important;
  font-family: "Font_Bold" !important;
  letter-spacing: 1px;
}

.other-td-scss {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: 10px;
}

.peer-table-header {
  //for entire table
  .p-table tbody > tr.p-widget-content {
    border-left-style: hidden;
    border-right-style: hidden;
    border-top-style: hidden;
    border-bottom-style: hidden;
  }
  //for Table-Headers
  .p-table .p-table-thead > tr > th {
    border-color: #f0f3f8;
    // color: #3e5c84;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    background-color: #ffffff;
    border-style: unset !important;
  }
  //For Table-Body
  .p-table tbody td {
    // color: #366ccc;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    font-size: 14px;
    border-style: unset !important;
  }
}
.peer-table {
  .p-table-odd {
    background: #ffffff;
  }

  .p-table-even {
    background: #f2f5f9;
  }
}

// p-table in generate email screens
.gen-email-p-table {
  .email-gen-search {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px !important;
  }
  .p-table-caption {
    padding-right: 0px;
    padding-top: 0px;
  }
  //for headers of the table
  .p-table .p-table-thead > tr > th,
  .p-table .p-table-tfoot > tr > td {
    border-color: #f0f3f8;
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    // background-color: #3f5c83;
    border-style: unset !important;
  }
  //entire table body
  .p-table .p-table-tbody > tr > td {
    border: hidden !important;
    // color: #366ccc;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    padding-left: 15px;
  }
  //to make row color with alternate colors
  .p-table .p-table-tbody > tr:nth-child(odd) {
    background: #ffffff;
  }
  .p-table .p-table-tbody > tr:nth-child(even) {
    background: #f2f5f9;
  }
  .p-table tbody tr td {
    div,
    span {
      text-overflow: unset !important;
      overflow: unset !important;
      white-space: unset !important;
    }
  }
  .p-table-caption {
    padding-left: 0px;
  }
}

.firm-liq-p-table {
  .p-table .p-table-tbody > tr > td {
    border-left: none;
    border-right: none;
    border-top: none;
    background: #ffffff;
  }
  .p-paginator-bottom {
    margin-top: 15px;
  }
  .p-table tbody tr td {
    div,
    p,
    span {
      text-overflow: unset !important;
      overflow: unset !important;
      white-space: unset !important;
    }
  }
}
.p-table-secondary {
  .p-table .p-table-thead > tr > th,
  .p-table .p-table-tfoot > tr > td {
    background: #ffffff !important;
    font-size: 15px !important;
    font-weight: bold;
    height: 60px;
    text-align: left;
    color: #0b3063;
    border: 0px !important;
    border-bottom: 2px solid #d9d9d93d !important;
  }
  .p-table .p-table-tbody > tr > td {
    border: 0px !important;
    font-size: 14px;
    font-weight: bold;
    color: #5f6772;
    text-align: left;
    height: 57px !important;
    border-bottom: 2px solid #e7ebef !important;
    background: #ffffff;
  }
  .p-table tbody tr td {
    div,
    p,
    span {
      text-overflow: unset !important;
      overflow: unset !important;
      white-space: unset !important;
      word-wrap: break-word;
    }
  }
}

.p-table-third {
  //for headers of the table
  .p-table .p-table-thead > tr > th,
  .p-table .p-table-tfoot > tr > td {
    background: #e7ebef;
    font-family: "Font_Light" !important;
    font-size: 15px !important;
    padding-bottom: 10px;
    text-align: -webkit-center;
    border: 0px;
    opacity: 0.7;
    outline: none;
    color: black;
    font-weight: bold;
  }
  //entire table body
  .p-table .p-table-tbody > tr > td {
    border: 0px !important;
    color: #5f6772;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-left: 15px !important;
    padding: 0.25em 0.5em;
  }
  .p-table table {
    border: 2px solid #e7ebef;
  }
  //to make hover on row
  .p-table .p-table-tbody > tr:hover {
    border: none !important;
    background-color: $primaryLightenColor !important;
  }
  .p-paginator-bottom {
    margin-top: 15px;
  }
  .p-table tbody tr td {
    div,
    p,
    span {
      text-overflow: unset !important;
      overflow: unset !important;
      white-space: unset !important;
      word-wrap: break-word;
    }
  }
}

.p-table-quant {
  .p-table .p-table-thead > tr > th,
  .p-table .p-table-tfoot > tr > td {
    border-color: #f0f3f8;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #f0f3f8;
    font-weight: normal;
  }
  .p-table .p-table-tbody > tr > td {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #ffffff;
    border-top-style: unset !important;
    border-right-style: unset !important;
    border-left-style: unset !important;
  }
  .p-table .p-table-tbody > tr:nth-child(odd) {
    background: #ffffff;
  }
  .p-table .p-table-tbody > tr:nth-child(even) {
    background: #f2f5f9;
  }
  .p-paginator-bottom {
    margin-top: 15px;
  }
  .p-table tbody tr td {
    div,
    p,
    span {
      text-overflow: unset !important;
      overflow: unset !important;
      white-space: unset !important;
      word-wrap: break-word;
    }
  }
}

.role-check-box {
  .p-chkbox .p-chkbox-box {
    width: 20px !important;
    height: 20px !important;
    margin-left: 0px !important;
    line-height: 1.125em !important;
    border-radius: 2px !important;
    text-align: center !important;
    padding-top: 2px !important;
  }
}

.db-header-alg .p-table .p-table-thead > tr > th {
  background: #e7ebef;
  font-family: "Font_Light" !important;
  font-size: 15px !important;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: left !important;
  border: 0px;
  opacity: 0.7;
  outline: none;
  .p-chkbox .p-chkbox-box {
    width: 20px;
    height: 20px;
    margin-left: 7px !important;
    line-height: 1.125em;
    border-radius: 2px;
    text-align: center;
  }
  .p-column-title {
    margin-left: 10px !important;
  }
}

.db-header-alg {
  .p-table th.p-state-active {
    background: #186ba0 !important;
    font-weight: 700;
    .p-column-title {
      color: white;
    }
  }
}

.p-table .p-table-tbody > tr {
  border: 0px !important;
}

.db-header-alg .p-table tbody td {
  border: 0px !important;
  color: #5f6772;
  letter-spacing: 1px;
  padding-left: 15px;
  .viewColor {
    .p-button-text {
      text-align: center;
    }
  }
  .p-button {
    background: #ffffff !important;
    font-size: 15px; // height: 25px;
    text-align: center;
    color: #114591;
    border: 1px solid #114591 !important;
    font-weight: normal !important;
  }

  // .p-button:hover {
  //   background: #114591 !important;
  //   color: #ffffff;
  // }
  .p-button-text {
    padding-top: 2px !important;
    padding-bottom: 1px !important;
  }
  .p-button:active {
    transform: translateY(1px) !important;
  }
}

.db-header-alg .p-table tbody tr {
  border: none !important;
  height: 52px;
  .case-col-alg .p-cell-data {
    margin-left: 10px !important;
  }
}

.db-header-alg .p-rowgroup-header {
  background: #114591 !important;
}

.db-header-alg .p-rowgroup-header:hover {
  background: #114591 !important;
}

.db-header-alg .p-rowgroup-header td span.p-rowgroup-header-name {
  color: #ffffff !important;
}

.db-header-alg .p-widget-header .p-table tbody tr:hover {
  background-color: #114591 !important;
}

.peer-fund-m-t-0 {
  .p-table table {
    margin-top: 80px !important;
  }
}
.peer-fund-scroll {
  .p-table-resizable {
    overflow: unset !important;
  }
  .p-table table {
    width: 1500px !important;
    // overflow-x: auto !important;
  }
  .p-tabview {
    background: white !important;
  }
  .p-tabview .p-tabview-nav > li {
    background: white !important;
  }

  li {
    border: none;
  }

  .twoColLen {
    width: 175px !important;
  }
  .fourColLen {
    width: 500px !important;
  }
  .fourColLen {
    width: 600px !important;
  }
  // .p-table-tablewrapper.ng-star-inserted{
  // width: 1000px !important;
  // overflow-x: auto;
  // }
  .p-state-default {
    border: none !important;
  }

  .p-table-odd {
    background: #ffffff;
  }

  .p-table-even {
    background: #f2f5f9;
  }

  tr > th.p-state-default.p-unselectable-text.p-resizable-column {
    text-align: left !important;
    font-weight: bold;
    color: #3e5c84;
  }
  .p-table .p-paginator-bottom {
    padding-bottom: 30px !important;
  }
  tr > th.p-state-default.p-unselectable-text.p-resizable-column:nth-child(1) {
    text-align: left !important;
  }
  tr > th.p-state-default.p-unselectable-text.p-resizable-column:nth-child(2) {
    text-align: left !important;
  }
  tr > th.p-state-default.p-unselectable-text.p-resizable-column:nth-child(3) {
    text-align: left !important;
  }
  .p-table-resizable thead th {
    white-space: normal;
  }
  thead.p-table-thead {
    border: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th {
    font-size: 20px !important;
  }
  span.p-tabview-title {
    font-size: 20px !important;
  }

  tr:nth-child(1) {
    th {
      border-right: 1px solid #ffffff !important;
    }
    th:nth-child(4) {
      text-align: center !important;
    }
    th:nth-child(5) {
      text-align: center !important;
    }
    th:nth-child(6) {
      text-align: center !important;
    }
    th:nth-child(7) {
      text-align: center !important;
    }
  }
  tr:nth-child(2) {
    th:nth-child(2) {
      border-right: 1px solid #ffffff !important;
    }
    th:nth-child(6) {
      border-right: 1px solid #ffffff !important;
    }
    th:nth-child(10) {
      border-right: 1px solid #ffffff !important;
    }
  }
  span.p-cell-data {
    text-align: left !important;
  }
  .p-table .p-table-thead > tr > th .p-column-title {
    margin-left: 0px !important;
  }

  .p-table-wrapper {
    overflow-x: unset !important;
  }
}

.premium-discount {
  display: block;
  overflow: scroll;
}

.suspend-stock-p-table-one {
  .p-table tbody > tr {
    height: 208px;
    vertical-align: top;
  }
  .p-table .p-table-tbody > tr > td {
    border-left-style: unset;
    // border-color: #366ccc;
  }
  .p-table tbody tr td {
    div,
    p,
    span {
      text-overflow: unset !important;
      overflow: unset !important;
      white-space: unset !important;
    }
  }
}
.suspend-stock-p-table-two {
  .p-table .p-table-thead > tr > th {
    color: white;
    // background-color: #366ccc;
    border: none;
    height: 38px;
    width: 102px;
  }
  .p-table .p-table-tbody > tr > td {
    border-left-style: unset;
    border-right-style: unset;
    // border-color: #366ccc;
    text-align: center;
  }
  .p-table tbody > tr {
    height: 208px;
  }
}
.suspend-stock-p-table-three {
  .p-table tbody > tr {
    height: 208px;
    vertical-align: top;
  }
  .p-table .p-table-tbody > tr > td {
    border-right-style: unset;
    // border-color: #366ccc;
  }
  .p-table tbody tr td p {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
  .p-table tbody tr td {
    div,
    label {
      text-overflow: unset !important;
      overflow: unset !important;
      white-space: unset !important;
    }
  }
}

.premium-table .p-table .p-table-thead > tr > th .p-column-title {
  margin-left: 10px !important;
  color: #114591 !important;
  font-weight: bold;
}
.premium-table .p-table .p-table-thead > tr > th {
  background: #f0f3f8;
  font-family: "Font_Light" !important;
  font-size: 16px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left !important;
  border: 0px;
  outline: none;
}
.premium-table .p-table tbody td {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #eaeff4 !important;
  color: #114591;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 0.6;
  font-weight: normal;
  letter-spacing: 1px;
  padding-left: 15px !important;
}

.premium-table .p-widget-content {
  border: none !important;
}
.premium-table.p-table-resizable {
  padding-bottom: 1px;
  overflow-x: hidden !important;
}

.premium-table .p-table .p-table-scrollable-body {
  height: calc(100vh - 600px);
  overflow-y: auto;
}
.prfrmnc-info-table {
  .p-table .p-table-thead > tr > th,
  .p-table .p-table-tbody > tr > td,
  .p-table .p-table-tfoot > tr > td {
    border: 1px solid #f6f7f9 !important;
    background: #f6f7f9 !important;
    color: #878788;
    font-weight: bold;
  }
}
.Success {
  color: green;
}

.Failed {
  color: red;
}
.Duplicate {
  color: #0b3063;
}
.t-type-drpdown {
  .p-dropdown {
    min-width: unset !important;
  }
}

// .custom-scrollbars {
//   ::-webkit-scrollbar {
//     width: 5px;
//     height: 5px;
//     background-color: #f5f5f5;
//   }
//   ::-webkit-scrollbar-track {
//     border-radius: 10px;
//     background-color: #f5f5f5;
//   }
//   ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: $menuScroll !important;
//   }
// }

.roles-p-table {
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th > span {
    padding-left: 5px;
  }
}

.user-groups-head-padding {
  .p-table .p-table-thead > tr > th > span {
    padding-left: 5px;
  }
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
}

.funds-head-padding {
  .p-table .p-table-thead > tr > th > span {
    padding-left: 5px;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(3) {
    padding-left: 20px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(4) {
    padding-left: 25px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(5) {
    padding-left: 30px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(6) {
    padding-left: 30px !important;
  }
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
}

.holiday-calendar-head-padding {
  .p-table .p-table-thead > tr > th > span {
    padding-left: 5px;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(3) {
    padding-left: 25px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(4) {
    padding-left: 25px !important;
  }
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
}

.jobs-head-padding {
  .p-table .p-table-thead > tr > th > span {
    padding-left: 5px;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(1) {
    padding-left: 15px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(2) {
    padding-left: 25px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(3) {
    padding-left: 30px !important;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(4) {
    padding-left: 35px !important;
  }
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
}

.jobs-head-error {
  .p-datatable .p-datatable-thead > tr > th {
    text-transform: none;
  }
}

.scheduler-head-padding {
  .p-table .p-table-thead > tr > th > span {
    padding-left: 5px;
  }
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
}
.fxRate-head-padding {
  .p-table .p-table-thead > tr > th > span {
    padding-left: 5px;
  }
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
}
.prices-head-padding {
  .p-table .p-table-thead > tr > th > span {
    padding-left: 5px;
  }

  td.p-resizable-column.ng-star-inserted:nth-child(4) > div {
    float: right;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(5) > div {
    float: right;
  }
  td.p-resizable-column.ng-star-inserted:nth-child(6) > div {
    float: right;
  }
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
}
.user-head-padding {
  td.ng-star-inserted:nth-child(4) {
    padding-left: 25px !important;
  }
  .p-table .p-table-thead > tr > th:nth-child(n) {
    border-right: 1px solid #ffffff !important;
  }
  .p-table .p-table-thead > tr > th:nth-last-child(1) {
    border-right: 0px solid #ffffff !important;
  }
}

.innertable-margin {
  th.p-l-10.p-t-20.p-b-0.p-sortable-column.p-resizable-column.ng-star-inserted {
    padding-left: 0px !important;
  }
  td {
    padding-left: 5px !important;
  }
}

.home-bg-color .p-state-highlight {
  .p-sortable-column-icon {
    color: #1b1d1f !important;
  }
  background-color: #f0f3f8 !important;
  color: #1b1d1f !important;
}
.case-table-tr {
  .p-table .p-table-tbody > tr.p-state-highlight {
    background-color: unset !important;
  }
}

.p-chkbox .p-state-default {
  border: 1px solid #8a8989 !important;
}
.file-upload-btn-ref {
  .p-datepicker.p-widget {
    right: 0px !important;
    left: -141px !important;
  }
  .p-widget input {
    width: 112px;
    height: 32px;
    opacity: 0;
    cursor: pointer;
  }
}
.calender-footer {
  .upload-btn .p-button .p-button-icon-left {
    display: none;
  }
  .upload-btn .p-button .p-button-text {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .p-button {
    background-color: white !important;
    color: #143e7d !important;
  }
}
.flex-file-upload-ref .p-fileupload-choose {
  display: none !important;
}

.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item.p-highlight {
  background: #366ccc !important;
  color: #ffffff;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  color: #114591 !important;
  background: none !important;
}
.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  width: 20px !important;
  height: 20px !important;
}
