//importing font families for ccass
@font-face {
   font-family: Gilroy-B;
   src: url(../fonts/Gilroy-Bold.ttf) format("truetype");
   font-style: normal;
 }
 @font-face {
   font-family: Gilroy-R;
   src: url(../fonts/Gilroy-Regular.ttf) format("truetype");
   font-style: normal;
 }
 @font-face {
   font-family: Gilroy-L;
   src: url(../fonts/Gilroy-Light.ttf) format("truetype");
   font-style: normal;
 }
 @font-face {
   font-family: Gilroy-M;
   src: url(../fonts/Gilroy-Medium.ttf) format("truetype");
   font-style: normal;
 }
 @font-face {
   font-family: Gilroy-H;
   src: url(../fonts/Gilroy-Heavy.ttf) format("truetype");
   font-style: normal;
 }