.p-highlight {
    // background: #114591 !important;
    color: #ffffff;
    font-family: 'Font_Regular';
    font-weight: bold !important;
  }

 .p-dropdown-item {
    margin: 2px !important;
    color: #222222;
    font-family: 'Font_Regular' !important;
    font-weight: bold !important;
    border-radius: 5px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff !important;
    font-weight: bold !important;
    background: #114591 !important;
}