@import "./assets/sass/themes/theme-var-config.scss";
@import "./assets/sass/abstracts/variables";
@import "./assets/sass/components/buttons";
@import "./assets/sass/components/inputs";
@import "./assets/sass/components/tabs";
@import "./assets/sass/components/cards";
@import "./assets/sass/components/paginater";
@import "./assets/sass/components/dialog";
@import "./assets/sass/components/table";
@import "./assets/sass/components/accordion";
@import "./assets/sass/globalStyles";

@import "./assets/sass/_cop";
@import "./assets/sass/_margins";
@import "./assets/sass/_paddings";
@import "./assets/sass/_labels";
@import "./assets/sass/_table";
@import "./assets/sass/_dropdown";
@import "./assets/sass/_overrides";
@import "./assets/sass/_pTable";

@import "./assets/sass/_calendar";
@import "./assets/sass/_media";
@import "./assets/sass/_dashboard";
@import "./app/kyc/add-new-case/add-new-case.component.scss";
@import "./app/admin/userstatistics/userstatistics.component.scss";
@import "./app/newsletters/newsletter.scss";
@import "./app/app.topbar.component.scss";

// CCASS Styles Imports
@import "./app/ccass/ccass_styles.scss";
@import './assets/ccass/sass/ccass_fonts.scss';

//calender style of highcharts
@import 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.8/themes/base/jquery-ui.css';

.pointer {
  cursor: pointer !important;
}

.p-dropdown-trigger {
  border: 0px;
}

.pd-drpDwn-label .p-dropdown label.p-dropdown-label {
  padding-left: 10px !important;
  padding-top: 10px !important;
  color: #5f6772 !important;
}

.drpDwn-label .p-dropdown label.p-dropdown-label {
  padding-left: 10px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  color: #5f6772 !important;
}

.layout-wrapper .topbar #topbar-menu > li a span {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.case-panel-height {
	.p-tabview > .p-tabview-nav {
    height: calc(100vh - 212px);
    background-color: #e7ebef !important;
    padding-top: 15px !important;
    width: 300px !important;
    border-radius: 0px !important;
    position: fixed;
    overflow: auto;
	display: block;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
	border: none !important;
	}

	.p-tabview-panels {
		position: relative;
		left: 300px;
	}
}

.tabs-panel {
  //Create Case Left menu
  .p-tabview > .p-tabview-nav > li,
  .p-tabview > .p-tabview-nav > li {
    height: 68px !important;
    border: none !important;
    width: 100% !important;
	padding-left: 20px;
  }
  .p-tabview .p-tabview-nav > li {
    background: #e7ebef;
  }
  .p-tabview > .p-tabview-nav > li:not(.p-highlight):not(.p-disabled) .p-tabview-nav-link  {
	background: #e7ebef;
  }
  .p-tabview-panels {
    width: calc(100% - 300px) !important;
    padding-left: 35px;
  }
  .p-tabview .p-tabview-nav li .p-tabview-title {
    font-size: 16px;
    color: #5f6772;
    font-weight: bold;
  }
  .fund-tabView {
    .p-tabview .p-tabview-nav > li.p-state-active {
      background-color: #ffffff !important;
    }
    .p-tabview .p-tabview-nav > li.p-state-default {
      background: #ffffff;
      font-size: 16px;
      font-weight: bold;
    }
    .p-tabview .p-tabview-nav li {
      list-style: none;
      float: left;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      position: relative;
      margin: 0px 1.125em 15px 0;
      padding: 0;
      white-space: nowrap;
    }
    .p-tabview .p-tabview-nav > li.p-state-active .p-tabview-title {
      font-size: 18px;
      font-weight: bold;
    }
    *:focus {
      outline: none;
    }
  }
  .p-tabview {
    padding-left: 0px;
  }
}

.fund-tabView .p-tabview .p-tabview-nav > li.p-state-default {
  background: #ffffff;
  font-weight: bold;
  border-bottom: 3px solid #e7ebef;
}
.db-header-alg {
  .p-state-highlight {
    border-color: #ffffff !important;
    background: #ffffff !important;
    color: #ffffff;
  }
}

.db-header-alg .p-rowgroup-header td span.p-rowgroup-header-name {
  color: #ffffff !important;
}

//Create Case
.cc-header-alg .p-table .p-table-thead > tr > th {
  background: #ffffff !important;
  font-family: "Font_Light" !important;
  color: #5f6772;
  font-size: 15px !important;
  height: 60px;
  text-align: left !important;
  border: 0px !important;
  border-bottom: 2px solid #d9d9d93d !important;
  opacity: 0.5;
}

.cc-header-alg .p-widget-content {
  border: 0px !important;
}

.cc-header-alg .p-table .p-table-scrollable-body {
  height: auto !important;
}

.cc-header-alg .p-button-text-icon-left .p-button-text {
  padding-left: 20px !important;
}

.cc-header-alg .p-table .p-table-scrollable-view {
  border: none !important;
}

.cc-header-alg .p-table tbody td {
  border: 0px !important;
  color: #5f6772;
  font-size: 14px;
  height: 80px !important;
  border-bottom: 2px solid #d9d9d93d !important;
  .p-cell-data {
    margin-left: 0% !important;
  }
}

.p-tabview.p-widget-content {
  font-family: "Font_Regular" !important;
}

.menu-svg-20 {
  svg {
    width: 20px !important;
    cursor: pointer;
  }
}

.menu-svg-50 {
  svg {
    width: 50px !important;
  }
}

.p-column-filter.p-dropdown.p-widget.p-state-default.p-dropdown-open {
  border-style: solid !important;
}

.p-dropdown.p-widget.p-state-default.p-corner-all.p-helper-clearfix.p-dropdown-open {
  border-style: solid !important;
}

.p-dropdown-panel .p-dropdown-item {
  font-size: 15px !important;
  padding: 10px !important;
  // font-weight: bold !important;
}

.p-dropdown-item.p-corner-all.p-state-highlight.ng-star-inserted {
  color: #ffffff !important;
}

.p-tabview .p-tabview-panel {
  padding-top: 0px !important;
}

.p-dropdown-item.p-corner-all.p-state-highlight.ng-star-inserted {
  list-style: none !important;
  padding: 10px !important;
}

.p-dropdown:hover .p-dropdown-menu {
  display: block;
}

.p-dropdown-menu li {
  padding: 0px;
}

.p-dropdown-item.p-corner-all.ng-star-inserted {
  padding: 10px !important;
}

.p-dropdown-trigger.p-state-default.p-corner-right {
  overflow: visible !important;
  background: none;
}

.p-messages-error {
  background-color: #fe5711 !important;
  border-color: #fe5711 !important;
  font-size: 16px !important;
  width: 420px;
  color: #ffffff !important;
}

.p-messages-icon {
  display: none !important;
}

.p-colorpicker-panel.p-corner-all.ng-trigger.ng-trigger-panelState {
  display: grid !important;
  opacity: 1 !important;
}

.set-heightx {
  height: 175px !important;
  width: 175px !important;
}

@-moz-document url-prefix() {
  .moz-input-font {
    font-size: 18px;
  }
  .moz-login-font {
    width: 249px !important;
    font-size: 18px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .chrome-input-font {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  body {
    width: 1024px !important;
    overflow-x: scroll !important;
  }
  .topbar {
    width: 1005px !important;
  }
}

// .p-datatable-resizable thead th,
// .p-datatable-resizable tbody td,
// .p-datatable-resizable tfoot td .actions {
//   white-space: nowrap;
//   font-weight: bold;
// }

.p-inputtext.p-state-focus,
.p-inputtext:focus {
  box-shadow: none !important;
}

// .p-radiobutton-icon {
//   display: block !important;
//   font-size: 0.6em !important;
//   line-height: inherit !important;
//   margin-top: -0.9em !important;
// }

//For panel-header
.p-widget-header {
  font-weight: bold !important;
  margin-top: 0px;
}

.bold-color {
  font-weight: bold !important;
}

.countries .p-dropdown .p-dropdown-trigger .fa {
  margin-top: 0px !important;
  vertical-align: -webkit-baseline-middle;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-l-n-1 {
  margin-left: -1px !important;
}

.w-100 {
  width: 100% !important;
}

.bg-clr {
  background-color: #eeeeee;
}

.if-no-sign {
  display: flex;
  height: 200px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
  width: 400px;
  align-items: center;
  background-color: #eeeeee;
}

.if-sign {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
  width: 400px;
  align-items: center;
  background-color: #eeeeee;
}

.password a:hover {
  color: #4c7d4c !important;
}

.stock-date {
  .p-calendar.p-calendar-w-btn input {
    cursor: pointer;
    border-radius: 4px;
  }
  .p-calendar .p-calendar-button {
    background: none !important;
    font-size: 16px;
    border-left: 0 none;
    height: 40px !important;
    bottom: 3px;
    right: 5px;
  }
  .p-button-icon-only .p-button-icon-left {
    top: 55%;
    font-size: 1.3em;
  }
  .p-button,
  .p-button:hover {
    border: none !important;
  }
}

.todayButton {
  display: none !important;
}

.full-width {
  height: 100% !important;
  width: 100% !important;
}

.at-center {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
}

.btn-yesno-width {
  text-align: center !important;
  width: auto !important;
  border: 0px !important;
}

.set-div-height {
  height: 30px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.p-tabview .p-tabview-nav li .p-tabview-left-icon {
  padding-right: 6px !important;
  opacity: 0.5 !important;
  font-size: 20px;
}

.p-messages-success {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  font-size: 16px !important;
  width: 420px;
  color: #ffffff !important;
}

.input-alg .p-dropdown label.p-dropdown-label {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

//After Refracor
.feedback-clr {
  color: #ffffff;
}

.w-60 {
  width: 60% !important;
}

.display-none {
  display: none !important;
}
.display-inline {
  display: inline !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}
.w-35 {
  width: 35% !important;
}
.w-22 {
  width: 22% !important;
}

.w-30 {
  width: 30% !important;
}

.w-10 {
  width: 10% !important;
}
.w-8 {
  width: 8% !important;
}
.w-16 {
  width: 16% !important;
}
.w-18 {
  width: 18% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 75% !important;
}

.w-25 {
  width: 25% !important;
}
.w-25-px {
  width: 25px !important;
}
.w-30-px {
  width: 30px !important;
}
.w-12-px {
  width: 12px !important;
}

.w-99 {
  width: 99% !important;
}
.border-none {
  border: none !important;
}
.border-zero {
  border: 0px !important;
}

.boxed {
  border: 1px solid #ccc !important;
}

.f-16 {
  font-size: 16px;
  font-weight: bold !important;
}
.f-24 {
  font-size: 24px;
}
.f-11 {
  font-size: 11px;
}
.f-15 {
  font-size: 15px !important;
  opacity: 1;
  font-weight: 700;
}
.f-18 {
  font-size: 18px !important;
  opacity: 1;
  font-weight: 700;
}
.f-s-18 {
  font-size: 18px !important;
}
.f-s-16 {
  font-size: 16px !important;
}
.f-s-13 {
  font-size: 13px !important;
}
.align-right {
  text-align: right;
}

.p-dropdown-panel .p-dropdown-filter {
  height: 40px !important;
}

.p-dropdown-panel .p-dropdown-filter-container {
  padding: 0px !important;
}

.inline-drpdwn.drop-down .p-widget-header .p-inputtext {
  height: 38px !important;
  color: #5f6772 !important;
  font-weight: bold;
}

.drop-down .p-dropdown label.p-dropdown-label {
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}

.drop-down .p-dropdown .p-dropdown-trigger {
  padding-top: 5px !important;
}

.fund-tabView {
  .p-tabview .p-tabview-nav > li.p-state-active {
    background-color: #ffffff !important;
    color: $secondaryColor;
  }
  .p-tabview .p-tabview-nav > li.p-state-default {
    background: #ffffff;
    font-size: 16px;
    margin-left: 0px !important;
    margin-right: 10px !important;
    font-weight: bold;
    border-bottom: 3px solid #e7ebef;
  }
  .p-tabview .p-tabview-nav li {
    list-style: none;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    position: relative;
    margin-left: 10px;
    padding: 0;
    white-space: nowrap;
  }
  .p-tabview {
    padding-left: 0px !important;
  }
  .p-tabview .p-tabview-nav{
    border: 0;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border-bottom: 3px solid $primaryColor;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus{
    box-shadow: none;
  }
  .p-tabview .p-tabview-nav > li.p-state-active .p-tabview-title {
    color: #494646;
  }

  .p-tabview
    .p-tabview-nav
    > li:not(.p-state-active):not(.p-state-disabled):hover {
    background-color: #ffffff;
    a {
      color: #757575 !important;
    }
  }
  .p-tabview .p-tabview-nav li a,
  .p-tabview.p-tabview-collapsible .p-tabview-nav li.p-tabview-selected a {
    padding: 5px 10px 5px 11px !important;
    font-size: 18px;
    color: #757575 !important;
  }
}

.font-weight {
  font-weight: bold;
}

// For table-column select dropdown
.p-multiselect:not(.p-state-disabled):hover {
  background: $primaryColor !important;
}
.p-multiselect {
	background: $primaryColor !important;
}
#navi {
  top: 0;
  left: 0;
}

#infoi {
  bottom: 28px;
  font-weight: bold;
  z-index: 10;
  position: relative;
  left: 18px;
  color: #ffffff;
  right: 0px;
  height: 0px;
  width: 0px !important;
}

.p-multiselect .p-multiselect-label {
  opacity: 0;
}

.multi-selectdpdwn {
  .p-multiselect .p-multiselect-label-container {
    height: 38px !important;
    width: 220px !important;
  }
  .p-multiselect .p-multiselect-trigger {
    border-left: 0px !important;
  }
  .p-multiselect .p-multiselect-trigger .fa {
    color: #555555;
    margin-top: 11px;
  }
  .p-multiselect .p-multiselect-header .p-multiselect-filter-container .fa {
    top: 5px !important;
  }
  .p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
    top: unset;
  }
}

.multi-columns {
  .p-multiselect .p-multiselect-label-container {
    height: 33.719px !important;
    border-radius: 2px;
    width: 135px !important;
  }
  .p-multiselect .p-multiselect-trigger .fa {
    color: #ffffff;
    margin-top: 8px;
  }
  .p-multiselect-header .p-multiselect-close {
    right: 0px !important;
    top: 0px !important;
    font-size: 1.2em;
  }
  .p-inputtext {
    padding-left: 30px;
  }
  .p-multiselect .p-multiselect-panel {
    width: 400px;
    left: -68px;
    top: 38px;
  }
  .drop-down .p-widget-header .p-inputtext,
  .p-widget-content .p-inputtext {
    height: 40px !important;
  }
  .p-multiselect-panel .p-multiselect-item {
    border: 1px solid #ccc !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pi-chevron-down:before {
    // content: "\e906";
    color: white;
  }
  .p-multiselect-header
    .p-multiselect-filter-container
    .p-multiselect-filter-icon {
    position: absolute;
    top: 1em;
    left: 0.2em;
    font-size: 1.2em;
  }
  *:focus {
    outline: none;
  }
  .p-multiselect .p-multiselect-trigger {
    padding-right: 30px;
    border-left-width: 0px !important;
  }
}

//predefined multiselect class
.p-multiselect-header .p-multiselect-filter-container {
  width: 88%;
}

.p-widget-header {
  border: none;
}

.p-multiselect-header .p-multiselect-close {
  right: 0;
  top: 0;
}

.p-multiselect .p-multiselect-trigger .fa {
  color: #ffffff;
  margin-top: 18px;
}

.p-multiselect-panel .p-multiselect-item {
  border: 1px solid #95989a;
  padding: 5px 6px;
}

// .trade-date {
//   .p-calendar.p-calendar-w-btn input {
//     border: 0px !important;
//     cursor: pointer;
//   }
//   .p-calendar-w-btn {
//     width: 148px;
//   }
//   .p-calendar .p-calendar-button {
//     background: none !important;
//     color: #5f6772 !important;
//     font-size: 16px;
//     border-left: 0 none;
//     height: 40px !important;
//     top: -3px !important;
//   }
//   .p-button-icon-only .p-button-icon-left {
//     top: 55%;
//     font-size: 1.3em;
//   }
//   .p-button,
//   .p-button:hover {
//     border: none !important;
//   }
// }

.tmplt-approve-date {
  .p-calendar.p-calendar-w-btn input {
    border-radius: 3px;
    cursor: pointer; // width:75%;
  }
  .p-calendar-w-btn {
    width: 180px;
  }
  .p-calendar .p-calendar-button {
    background: none !important;
    color: #747576 !important;
    font-size: 16px;
    border-left: 0 none;
    height: 40px !important;
    top: -3px !important;
  }
  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    border: none !important;
  }
}

.trade-date1 {
  .p-calendar.p-calendar-w-btn input {
    border: 0px !important;
    width: 0px !important;
    cursor: pointer;
    padding: 0px;
  }
  .p-calendar-w-btn {
    width: 0px;
    height: 0px;
  }
  .p-calendar .p-calendar-button {
    background: none !important;
    color: #5f6772 !important;
    font-size: 16px;
    border-left: 0 none;
    height: 40px !important;
    top: -16px !important;
  }
  .p-calendar .p-button-icon-only .fa {
    margin-left: 20px;
    margin-bottom: 0px;
    top: 11px;
  }
  .p-button-icon-only .p-button-icon-left {
    top: 60%;
    font-size: 1.3em;
    margin-left: 0.8em;
  }
  .p-button,
  .p-button:hover {
    border: none !important;
    position: absolute;
    padding: 0px;
    background: #fff;
    color: #707070 !important;
  }
}

.fxrate-calender .p-calendar.p-calendar-w-btn input {
  height: 40px;
}

.disable {
  opacity: 0.6 !important;
  pointer-events: none !important;
}

.count-label {
  font-size: 16px;
  color: #5f6772;
}

.header-font {
  color: #323c47;
  font-size: 24px;
  font-family: "Font_Regular";
}

.b-0 {
  border: 0px !important;
}

.f-w-b {
  font-weight: bold !important;
}

.p-rowgroup-header {
  pointer-events: none !important;
}

.fund-font {
  color: #5e6772;
  font-size: 14px;
  font-weight: bold;
}

.p-slider {
  background: #cfdce5 !important;
}

.p-slider-handle {
  background: #e04f5f !important;
  border-radius: 50% !important;
  .p-slider-handle {
    top: -5px !important;
  }
}

.p-slider-handle.p-slider-handle {
  top: -5px !important;
}

.dateInput {
  .p-inputtext {
    padding: 10px;
  }
}

.filterDateInput {
  .p-inputtext {
    padding: 5px !important;
    width: 100px !important;
  }
}

.corn-frquecy .p-widget select {
  height: 40px !important;
  background: none !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
}

.show-menu {
  display: none !important;
}

.f-12 {
  font-size: 12px;
}

// .p-radiobutton-label {
//   opacity: 0.6 !important;
//   font-weight: 600 !important;
// }

.cashCmpAccordion {
  .p-accordion .p-accordion-header {
    background: #e6ecf2 !important;
    border-radius: 5px !important;
    border: none;
    padding-top: 8px;
    padding-left: 10px;
    height: 45px !important;
    font-size: 16px !important;
    margin-top: 20px;
    box-shadow: 0px 3px 6px rgba(21, 96, 144, 0.16);
    vertical-align: middle;
    font-weight: normal;
    a {
      color: #b3c4db !important;
      text-decoration: none !important;
    }
  }
  .p-accordion-content {
    border-left: 1px solid #e8ebf1;
    border-right: 1px solid #e8ebf1;
    border-bottom: 1px solid #e8ebf1;
    background: #ffffff;
    position: relative;
    box-shadow: 0px 3px 6px rgba(21, 96, 144, 0.16);
    color: #222222;
  }
  *:focus {
    outline: none;
  }
}

.cashCmpAccordion2 {
  .p-accordion .p-accordion-header {
    background: #fff !important;
    border-radius: 5px !important;
    border: none;
    padding-top: 8px;
    padding-left: 10px;
    height: 53px !important;
    font-size: 16px !important;
    margin-top: 20px;
    box-shadow: 0px 3px 6px rgba(21, 96, 144, 0.16);
    vertical-align: middle;
    font-weight: normal;
    a {
      color: #b3c4db !important;
      text-decoration: none !important;
    }
  }
  .p-accordion-content {
    border-left: 1px solid #e8ebf1;
    border-right: 1px solid #e8ebf1;
    border-bottom: 1px solid #e8ebf1;
    background: #ffffff;
    position: relative;
    height: 350px;
    overflow-y: auto;
    box-shadow: 0px 3px 6px rgba(21, 96, 144, 0.16);
    color: #222222;
  }
  *:focus {
    outline: none;
  }
}
//fieldset underline
.p-fieldset-toggleable .p-fieldset-legend a {
  text-decoration: none;
}

.align-center {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.cashPositionUpload {
  .p-button {
    width: 120px;
    height: 120px;
    border: none;
    background: unset !important;
    background: url(/assets/images/upload.svg) no-repeat !important;
  }
}
.pcfUpload {
  .p-button {
    width: 105px;
    height: 105px;
    border: none;
    background: unset !important;
    background: url(/assets/images/fileUpload.svg) no-repeat !important;
  }
}

.hideUploadIcon {
  .p-button-icon-left {
    left: 0.5em;
    display: none !important;
  }
}

.large-Card {
  .p-card-shadow {
    padding: 0px 30px;
    width: 465px;
    height: 285px;
  }
}

// .roundChk.p-chkbox-box.p-state-active,
// .p-radiobutton-box.p-state-active {
//   border: 1px solid #5c8fd8;
//   background: #5c8fd8;
//   color: #ffffff;
// }

.roundChk.p-chkbox .p-chkbox-box {
  border-radius: 50% !important;
  width: 18px;
  height: 18px;
}

.roundChk.p-chkbox .p-chkbox-icon {
  line-height: 16px;
  font-size: 14px;
}

.premium-table .p-widget-content {
  border: none !important;
}
.positive {
  color: #3db36a;
}
.negative {
  color: #fe1e05 !important;
  opacity: 0.6;
}

// Dashboard fund card
.fund-card {
  .p-card {
    box-shadow: none;
    .p-card-body {
      padding: 0px 0px 8px 0px !important;
    }
  }
  .fund-pass {
    font-size: 26px;
    color: #49b7a4;
  }
  .fund-fail {
    font-size: 26px;
    color: #fe5751;
  }
  .fund-name {
    // color: #366ccc;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
  }
  .index-code-label {
    font-size: 11px;
    // color: #114591;
    font-weight: bold;
  }
  .index-code-value {
    font-weight: bold;
    font-size: 14px;
    // color: #366ccc;
  }
  .performance-label {
    color: $secondaryColor !important;
    font-size: 12px;
  }
}

.custom-tab-active {
  color: #757575;
  padding-bottom: 5px;
  padding-left: 5px;
  cursor: pointer;
}

.profile-upload-css {
  .p-fileupload-choose {
    position: absolute !important;
    left: 153px !important;
    width: 33px;
    height: 23px;
    opacity: 0 !important;
  }
  .p-button {
    padding: 14px !important;
  }
}
.remove-icon {
  cursor: pointer;
  color: #ccc;
}
.remove-icon:hover {
  color: #333;
}
.upload-icon {
  cursor: pointer;
  color: #ccc;
}
.upload-icon:hover {
  color: #333;
}
.user-img-popup {
  svg {
    width: 150px !important;
    height: 150px !important;
    margin-left: 10px;
  }
  width: 150px !important;
  height: 150px !important;
}

.date-pkr-input {
  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    height: 50px !important;
  }
}
.hr-style {
  border-width: 0.5px;
  margin-bottom: 5px;
}

.back-bg {
  position: absolute;
  height: 60px;
  width: 60px;
}
.back-alg {
  color: #ffff;
  padding-left: 16px;
  height: 0;
  top: -4px;
  position: absolute;
}

.priv-tags {
  .p-tabview.p-tabview-top > .p-tabview-nav {
    padding: 0px !important;
  }
  .p-tabview .p-tabview-panel {
    padding-bottom: 0px !important;
  }
  .p-tabview {
    padding: 0px !important;
  }
  .p-tabview .p-tabview-nav li {
    margin: 0px !important;
  }
  .p-state-default {
    border-right-width: 0px !important;
    border-radius: 0px;
  }
  .p-corner-all {
    border-radius: 0px;
  }
  .p-widget-header {
    font-weight: normal !important;
  }
}
.f-20 {
  font-size: 20px;
}

// Newsletter
gridster {
  background: #ffffff !important;
  padding: 0px !important;
}

.gridster-border {
  border: 1px solid #ccc !important;
}
gridster-preview {
  background: none !important;
}

.ng2-pdf-viewer-container {
  overflow-x: unset !important;
}

.cal-yr-asofdate {
  font-weight: bold;
  color: #707070;
  font-size: 13px;
}

.p-dropdown .p-dropdown-label {
  text-overflow: ellipsis;
}

.news-split {
  .p-menu.p-menu-dynamic.p-widget {
    overflow-y: auto;
    width: 350px;
    left: -60px !important;
    background: #ffffff;
    max-height: 300px !important;
    top: 35px !important;
  }

  span.p-menptem-text {
    font-size: 14px;
  }
  button {
    border-right-width: 0px !important;
    border-left-width: 0px !important;
  }
  button.p-button.p-widget.p-state-default.p-corner-left.p-button-text-only {
    pointer-events: none !important;
  }
  .p-button {
    transform: none !important;
  }
}

.fund-info {
  .p-menu.p-menu-dynamic.p-widget {
    width: 260px !important;
    left: -95px !important;
    background: #ffffff;
  }
}

.widgetul {
  ul {
    display: block !important;
  }
}

.parent-card {
  border: 10px solid #e7ebef;
  height: calc(100vh - 210px);
 // overflow: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}
.header-group {
  .p-dropdown-item-group {
    background-color: #e7ebef !important;
    padding: 10px 10px 8px 10px;
    margin-bottom: 10px;
  }
}

.footer {
  background-color: $primaryColor;
}
.kyc-p-b {
  p,
  b {
    color: #757575 !important;
  }
}

.e-rte-content {
  p,
  b {
    color: #757575;
  }
}

.mail-body-color {
  p {
    color: #757575;
  }
}
.border-top {
  border-top: 1px solid #ccc;
}
.white-space-nowrap {
  white-space: nowrap;
}

.opacity-06 {
  opacity: 0.6;
}
.opacity-07 {
  opacity: 0.7;
}
.opacity-100-pr {
  opacity: 100% !important;
}
.flex {
  display: flex !important;
}

.v-a-t {
  vertical-align: top !important;
}
.upper-case {
  text-transform: uppercase;
}
.c-stnd-inst {
  font-size: 16px;
  display: inline-block;
  margin-top: 2px;
}
.client-prof-form {
  border-bottom: 0.2px solid #a2a2a2;
}
.position-relative {
  position: relative;
}
.save-color {
  color: #91d093;
}

.cancel-color {
  color: red;
}
#days {
  color: $primaryColor;
}
.dashboardCardColor {
  color: $dashboardCardColor !important;
}
.d-card-val {
  color: $dCardValColor !important;
}
.d-card-header {
  color: $dCardHeader !important;
}
.hld-tab-boarder {
  border-bottom: 4px solid $secondaryColor !important;
}
.roles-bread-crumb {
  display: flex;
  color: $primaryColor;
  .fa-chevron-right {
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.highcharts-menu-item:hover {
  background: $primaryColor !important;
  color: #5f6772;
}
.submit-btn:hover {
  background: $secondaryColor !important;
  border: 1px solid $secondaryColor !important;
}
.td-anchor {
  color: $primaryColor;
}
.td-anchor:hover {
  color: $secondaryColor;
}
.red .p-widget-content {
  background-color: red !important;
}

.prices-main-class {
  .p-button {
    border-radius: 5px;
  }
  .p-button-label {
    margin-left: 0px;
    font-weight: bold;
    margin-right: -6px;
  }
}

.fair-value-adjustment {
  .p-datatable .p-datatable-thead > tr > th {
    background: #e9ecef !important;
  }
  .inline-editable-table-alg .p-datatable .p-datatable-thead > tr > th {
    font-family: "Font_Regular";
    color: #1b1d1f;
  }
  .p-datatable-scrollable-body
    > table
    > .p-datatable-tbody
    > tr:first-child
    > td {
    border-top: 0;
    font-family: "Font_Regular";
  }

  .p-datatable-scrollable-body {
    overflow-y: unset !important;
    overflow: unset;
  }
}

.account-mapping {
  .fee-type-border {
    width: 103%;
  }
  .p-datatable .p-datatable-thead > tr > th {
    background: #f0f3f8;
    font-family: "Font_Regular";
    font-weight: 100;
    color: #114591;
  }
  .p-datatable-scrollable-body
    > table
    > .p-datatable-tbody
    > tr:first-child
    > td {
    border-top: 0;
    font-family: "Font_Regular";
  }

  .p-datatable-scrollable-body {
    overflow-y: unset !important;
    overflow: unset;
  }
}

.bank-interest-maintenance {
  .p-datatable .p-datatable-thead > tr > th {
    background: #ebedf0;
    font-family: "Font_Regular";
    font-weight: bold;
    color: #1b1d1f;
  }
  .p-datatable-scrollable-body
    > table
    > .p-datatable-tbody
    > tr:first-child
    > td {
    border-top: 0;
    font-family: "Font_Regular";
  }
  .p-datatable-scrollable-body {
    overflow-y: unset !important;
    overflow: unset;
  }
  .p-button {
    border-radius: 5px;
  }
  .p-button-label {
    margin-left: -2px;
    font-weight: bold;
    margin-right: -6px;
  }
}

.fee-maintenance {
  .inline-editable-table-alg .p-datatable .p-datatable-thead > tr > th {
    background: #ebedf0;
    font-family: "Font_Regular";
    font-weight: bold;
    color: #1b1d1f;
  }

  .p-datatable tbody tr td div,
  .p-datatable tbody tr td p,
  .p-datatable tbody tr td span {
    color: black;
  }

  .p-datatable table {
    font-family: "Font_Regular";
  }
  .p-datatable-scrollable-body
    > table
    > .p-datatable-tbody
    > tr:first-child
    > td {
    border-top: 0;
    font-family: "Font_Regular";
  }
}

.exported-files-log {
  .p-datatable .p-datatable-thead > tr > th {
    background: #ebedf0;
    font-family: "Font_Regular";
    font-weight: bold;
    color: #1b1d1f;
  }
  .p-datatable-scrollable-body
    > table
    > .p-datatable-tbody
    > tr:first-child
    > td {
    border-top: 0;
    font-family: "Font_Regular";
  }
  .p-datatable-scrollable-body {
    overflow-y: unset !important;
    overflow: unset;
  }
  .p-button {
    border-radius: 5px;
  }
  .p-button-label {
    margin-left: -2px;
    font-weight: bold;
    margin-right: -6px;
  }
  // .p-dialog .p-dialog-header {
  //   padding: 0.85rem;
  // }
  .p-paginator-bottom {
    margin-top: 10px !important;
  }
}

.uploaded-files {
  .p-datatable .p-datatable-tbody > tr > td {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .p-datatable .p-datatable-thead > tr > th {
    background: #ebedf0;
    font-family: "Font_Regular";
    font-weight: bold;
    color: #1b1d1f;
  }
  .primary-p-table .p-datatable .p-datatable-thead > tr > th .p-inputtext {
    font-size: 1em;
  }

  .p-button {
    border-radius: 5px;
  }
  .primary-p-table .p-datatable tbody td .p-button {
    background: white;
    color: #114591;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.portfolio-codes {
  .p-datatable .p-datatable-thead > tr > th {
    background: #ebedf0;
    font-family: "Font_Regular";
    font-weight: bold;
    color: #1b1d1f;
  }

  .p-button {
    border-radius: 5px;
    font-weight: bold;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-style {
    font-family: "Font_Regular";
  }
}

// .add-account-maintenance-popup {
//   .p-dialog .p-dialog-content {
//     font-family: "Font_Regular";
//   }
//   .p-dialog .p-dialog-header {
//     margin-bottom: -15px;
//   }
// }

.premium-discount-summary-page {
  .p-button {
    border-radius: 5px;
    font-weight: bold;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.premium-discount-page {
  .p-checkbox .p-checkbox-box {
    border: 1px solid #ced1d5;
    border-radius: 50% !important;
  }
  .p-tag-inline {
    font-family: "Font_Regular";
    font-weight: 100;
  }
  .p-button {
    border-radius: 5px;
    font-weight: bold;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .back-font {
    font-family: "Font_Regular";
    font-weight: 100;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    display: contents;
  }
  .cashCmpAccordion .p-accordion .p-accordion-header {
    padding-top: 16px;
  }
}

.trade-dates-page {
  .txt-style {
    font-family: "Font_Regular";
  }
  .p-fluid .p-calendar .p-inputtext {
    width: 1%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .pi-calendar:before {
    color: white;
  }
  .p-datepicker-trigger {
    width: 30px !important;
  }
  .p-dialog-footer .p-button:enabled {
    background: #114591;
    color: white;
  }
  // .p-dialog .p-dialog-header {
  //   padding-top: 0.6em;
  // }
}

.fx-rates-page {
  // .p-dialog-header {
  //   display: unset;
  // }
  // .p-dialog .p-dialog-header {
  //   padding-top: 0.5em;
  // }
  // .p-dialog .p-dialog-content {
  //   font-family: "Font_Regular";
  // }
  .p-calendar-w-btn .p-datepicker-trigger {
    background: white;
  }
  .calendar-align {
    text-align: end;
  }
}

.daily-cr-orders-page {
  .p-checkbox .p-checkbox-box {
    border: 1px solid #ced1d5;
    border-radius: 50% !important;
  }
  .p-tag-inline {
    font-family: "Font_Regular";
    font-weight: 100;
  }

  .p-button {
    border-radius: 5px;
    font-weight: bold;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .back-font {
    font-family: "Font_Regular";
    font-weight: 100;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    display: contents;
  }
  .cashCmpAccordion .p-accordion .p-accordion-header {
    padding-top: 16px;
  }

  .p-dropdown .p-dropdown-label {
    font-size: 16px !important;
}
//   .trade-date .p-calendar.p-calendar-w-btn input {
//     font-size: 16px !important;
// }
.t-type-drpdown .p-dropdown {
  height: auto;
}
}

.firm-level-liquidity-page {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0px !important;
  }
}


// New Table Styles
// .p-primary-table {

// }
.w-100{
  width: 100%;
}
.p-radiobutton-disabled {
  cursor: not-allowed !important;
}