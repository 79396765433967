// Menu
$menuBGColor: $primaryColor;
$menuHeaderTextColor: $primaryTextColor;
$menuItemTextColor: $secondaryTextColor;
$menuItemTextHoverColor: $primaryTextColor;
$menuHRColor: $secondaryTextColor; //Horizatal ruler color

//HEADER
.top-btm-mrg {
  margin-bottom: 65px;
}

.top-bar-grid {
  height: $topBarHeight;
  margin: 10px;
  background: $header;
}

.logo-style {
  width: 168px !important;
  background-color: $logoBGColor;
  height: 69px;
  text-align: center;
  vertical-align: middle;
}
.hov {
  cursor: pointer;
}

.logo-svg {
  svg {
    width: 130px;
    fill: white !important;
  }
}

.onboarding-color {
  color: $primaryTextColor;
  font-size: 28px;
  font-weight: bold;
  margin-top: 17px;
  margin-left: 43px;
}
.dmp-color{
  color: $primaryTextColor;
  font-size: 14px;
  font-weight: bold;
  margin-top: 11px;
  margin-left: 10px !important;
}
.cls{
  margin: 0px !important;
  padding: 0px !important;
  // margin-top: 15px;
  // margin-left: 10px;
}
.padding{
  padding-top: 17px;
}
.pad{
  padding: 0px !important;
  text-align: center !important;
}
.dmp-title{
  text-align: center !important;
  padding: 3px !important;
  padding-top: 8px !important;
}
.par{
  white-space: nowrap;
}
.ipBox{
  background-color: #EFEFEF;
  width: 350px !important;
  color: #3E3C3C;
  font-size: 10px !important;
}
.adv{
  // display: inline-block;
  font-size: 12px !important;
  text-align: center;
  vertical-align: middle;
  margin-left: 120px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 13px;
  color: #ffffff;
  cursor: pointer;
  // margin-left: 440px !important;
  // margin-bottom: 150px !important;
}
.adv:hover{
  background-color: #0F376E;
  border-radius: 4px !important;
  // padding-top: 2px !important;
}
.label{
  font-size: small;
}
.menuitem{
  float: left;
}

.active {
  background-color: $iconActiveColor !important;
}

//MENU
.menu-svg {
  svg {
    width: 80px;
  }
}

.menu-notif {
  height: 69px;
}

.menu-bar-logo-style {
  width: 80px;
  height: 70px;
  text-align: center;
  vertical-align: middle;
  float: right;
  &:hover {
    background-color: $iconHoverColor;
  }
}

.mega-menu-container {
  height: 50vh;
  overflow: auto;
  padding: 0;
  top: 80px;
  right: -10px;
  position: absolute;
}

.mega_menu {
  display: flex;
  padding-right: 10px;
  // padding-left: 10px;
  float: unset;
  background-color: $menuBGColor;
  margin-top: -10px !important;
  top: 78px;
  left: 373px;
  z-index: 10000 !important;
  // overflow: inherit;
}
.mega_menu_col {
  position: relative;
  width: 265px;
  padding: 10px 10px 10px;
}
.mega_menu_col_quant {
  position: relative;
  border-right: 1px solid #ccc;
}

.header {
  position: static;
  margin-top: 0px;
  color: $menuHeaderTextColor;
  font-size: 16px;
}

.heading {
  margin-top: 0px;
  font-family: Montserrat, sans-serif;
  color: $menuItemTextColor;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  width: 245px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 5px 10px 5px 10px;
  display: table;
}

.heading.active {
  color: $primaryTextColor;
  font-size: 15px;
  font-weight: bold;
  opacity: 0.9;
}

.heading {
  .pointer:hover {
    color: $primaryTextColor;
  }
  // background: #0b3063 !important;
}

.mega_header {
  position: relative;
  display: block;
  width: 100%;
//   height: 40px;
  margin-top: 10px;
  background-color: $menuBGColor;
}

.menu_wrap {
  height: 91%;
}

@media (max-width: 770px) {
  .mega-menu-container {
    width: 100vh;
    height: 100vh;
  }
  .header {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
}

@media (min-width: 1400px) {
  .mega-menu-container {
    width: unset;
  }
}

@media (max-width: 479px) {
  .header {
    font-size: 14px;
  }
}

.show-menu {
  display: none !important;
}

.menu-hr {
  border-top: 0.3px solid $menuHRColor;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 0px;
  width: 115px;
}

//NOTIFICATIONS
.notif-hdr-height {
  height: 60px;
  background-color: $notificationsHeaderColor;
}

.bell-align {
  float: right;
  color: $primaryTextColor;
  margin-right: 22px !important;
  margin-top: 15px !important;
}

.layout-wrapper {
  .topbar {
    #topbar-notif {
      display: none;
      position: absolute;
      right: 70px;
      top: 80px;
      width: 41%;
      list-style-type: none;
      padding: 0;
      margin: 0;
      -webkit-animation-duration: 0.5s;
      -moz-animation-duration: 0.5s;
      animation-duration: 0.5s;
      &.topbar-menu-visible {
        display: block;
      }
    }
  }
}

.notif-logo-style {
  width: 80px;
  height: 70px;
  text-align: center;
  vertical-align: middle;
  padding-top: 11px;
  float: right;
  &:hover {
    background-color: $iconHoverColor;
  }
}

.notif-label {
  width: auto !important;
  float: left;
}

.notif-svg {
  svg {
    width: 34px;
    margin-top: 8px;
    margin-right: 5px;
    fill: white !important;
  }
}

//Notification Dropdown
.pending-dot {
  width: 31px;
  float: left;
}

.img-alg {
  width: 60px;
  float: left;
}

.notif-content-pd {
  padding-left: 105px;
}
.t-p-1px {
  padding-top: 1px;
}

// Notifcation Dropdown
.notif-template {
  background-color: #ffffff !important;
  border: 4px solid $notificationsBorderColor;
  height: 400px;
  overflow-y: auto;
}

//Notifications popup
.notif-popup {
  background-color: #ffffff !important;
  border: 4px solid $notificationsBorderColor;
  height: 600px;
  overflow-y: auto;
}

.notif-menu-dropdown {
  padding-top: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 20px !important;
  color: #333333;
}

.notif-label-color {
  color: $notificationsLblColor;
  margin-top: 23px;
  font-size: 17px;
  font-weight: bold;
}

.notif-label {
  width: auto !important;
  float: left;
}
@media screen and (max-width: 1920px) and (min-width: 1681px) {
  .ipBox{
    width: 700px !important;
  }
  .adv{
    font-size: 20px !important;
    margin-left: 270px !important;
  }
  .dmp-color {
    font-size: 20px;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .ipBox{
    width: 883px !important;
  }
  .adv{
    font-size: 20px !important;
    margin-left: 419px !important;
  }
  .dmp-color {
    font-size: 20px;
  }
}
