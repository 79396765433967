//Dashboard
.dashboard-card {
  .p-card {
    box-shadow: none;
  }
  
  .p-card-title {
    font-size: 16px;
    margin-bottom: 0px;
    // font-weight: bold;
  }
  .p-card-body {
    height: 233px;
    padding: 10px;
    border: 1px solid #D5D5D5 !important;
    border-radius: 5px;
    .total-aum {
      font-size: 14px;
      // color: #38598a;
      font-weight: bold;
    }
    .value-size {
      font-size: 60px;
      color:$secondaryColor;
      font-weight: bold;
    }
    .stocks {
      font-size: 14px;
      color:$primaryColor;
      font-weight: bold;
    }
    .p-card-content {
      padding-top: 0px;
    }
    .p-corner-all {
      border-radius: 2px !important;
    }
  }
}

.bottom-content {
  .p-card-body {
    border: 1px solid #D5D5D5 !important;
    border-radius: 5px;
    height: 213px;
  }
  .p-card-content {
    padding-top: 0px !important;
}
  
}

.filter-div {
  .filter {
    font-size: 11px !important;
  }
  .fa-filter {
    color: #b2b2b2;
  }
}

.stock-check-alg {
  .p-chkbox-label {
    font-size: 14px;
    color: #8f8e8a !important;
    font-weight: bold;
  }
  .p-dropdown label.p-dropdown-label {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .p-dropdown .p-dropdown-trigger .fa {
    margin-top: -4px !important;
  }
  .p-inputtext {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .p-corner-all {
    border-radius: 2px !important;
  }
}

//Dashboard
// Media queries
@media only screen and (max-width: 1680px) {
  .fund-text {
    font-size: 13px !important;
  }
  .dashboard-card {
    .p-card-title {
      font-size: 12px !important;
    }
    .p-card-body {
      height: 230px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
      .total-aum {
        font-size: 12px !important;
      }
      .value-size {
        font-size: 40px !important;
      }
      .stocks {
        font-size: 12px !important;
      }
    }
  }

  .fund-card {
    .p-card {
      box-shadow: none !important;
      .p-card-body {
        padding: 8px !important;
      }
    }
    .fund-pass {
      font-size: 18px !important;
    }
    .fund-fail {
      font-size: 18px;
    }
    .fund-name {
      font-size: 12px !important;
    }
    .index-code-label {
      font-size: 9px !important;
    }
    .index-code-value {
      font-size: 11px !important;
    }
    .performance-label {
      font-size: 9px !important;
    }
    .ytd-value {
      font-size: 10px !important;
    }
  }

    :host
    ::ng-deep
    .p-card-shadow {
      box-shadow: none !important;
    }
  
  .ytd-style {
    font-size: 7px !important;
  }
  .nav-value {
    font-size: 10px !important;
    // padding-top: 5px !important;
  }
  .nav-label {
    font-size: 10px !important;
  }
  .nav-asset-value {
    font-size: 10px !important;
    // padding-top: 5px !important;
  }
  .nav-asset-value-pc {
    font-size: 10px !important;
    // padding-top: 5px !important;
  }
  .aum-label {
    font-size: 10px !important;
  }
  .aum-value {
    font-size: 10px !important;
    // padding-top: 5px !important;
  }
  .td-label {
    font-size: 8px !important;
  }
  .td-value {
    font-size: 10px !important;
    // padding-top: 1px !important;
  }
  .td-value-pc {
    font-size: 8px !important;
    // padding-top: 2px !important;
  }
  
  .filetr-tabs-fs {
    font-size: 12px !important;
  }
  .db-tabs-alg {
    font-size: 12px !important;
  }
  .stock-check-alg {
    .p-chkbox-label {
      font-size: 12px !important;
    }
  }
}
// Date-picker
.chart-datepicker {
  .p-inputtext {
    padding: 5px 5px 5px 35px;
    font-size: 14px;
    font-weight: normal !important;
  }
  .p-corner-all {
    border-radius: 0px;
  }
  .p-calendar button {
    position: inherit;
    width: auto;
    font-size: 14px;
  }
  .p-calendar .p-datepicker-buttonbar {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    height: 30px;
    width: 30px;
    position: absolute;
    border: 1px solid #ccc !important;
    background: #fff !important;
    color: #5f6772 !important;
  }
  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button:hover {
    border: 1px solid #ccc !important;
    background: #fff !important;
    color: #ccc !important;
  }
  .p-inputtext:enabled:hover{
    border-color: #ccc;
  }
}

.fund-text {
  // color: #366ccc;
  font-size: 16px;
  white-space: normal !important;
  width: 100%;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical !important;
  box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

// @media only screen and (min-width: 1680px) {
//   .chart-datepicker {
//     .p-inputtext {
//       width: 20em !important;
//     }
//   }
// }

.p-checkbox-label {
   font-size: 14px;
   color: #535352 !important;
   font-weight: bold;
   margin-left: 5px;
 }

 .p-inputswitch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .3s;
  border-radius: 30px;
}
//Dashboard Filter Buttons

.filter-btn {

  .p-button {
    border: 1px solid #D3D3D3 !important;
    background: #fff !important;
    color: #939393 !important;
    height: 21px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;

  }
  .p-corner-all {
    border-radius: 3px !important;
  }
  .p-button-text-icon-right .fa {
    color: #D3D3D3 !important;
    font-size: 11px !important;
    margin-bottom: 2px;
  }
  .p-button:enabled:active {
    border: 1px solid #D3D3D3 !important;
    background: #fff !important;
    color: #D3D3D3 !important;
  }
}